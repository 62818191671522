import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import "./Failure.css";

const Failure = () => {
  const navigate = useNavigate();
  return (
    <div className="failure-container">
      <CancelOutlinedIcon className="icon" />
      <h4>Order Failed</h4>
      <p>
        We encountered an issue while processing your order. Please try again.
      </p>
      <div className="actionbtns">
        <button onClick={() => navigate("/")}>Back to Home</button>
        <button onClick={() => navigate("/support")}>Contact Us</button>
      </div>
    </div>
  );
};

export default Failure;
