import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { message } from "antd";
import { Link } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import IMAGES from "../img/image";
import axios from "axios";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import getUserData from "../utils/userDataService";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./Wallet.css";

const Wallet = () => {
  const { user } = useSelector((state) => state.user);
  const [tab, setTab] = useState(1);
  const [form, setForm] = useState(null);
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [balance, setBalance] = useState("");
  const [price, setPrice] = useState("");
  // payment mode
  const [paymentMode, setPaymentMode] = useState(null);
  const [desc, setDesc] = useState("");
  const [img, setImg] = useState(null);
  const [utr, setUtr] = useState("");
  // popup
  const [popup, setPopup] = useState(false);

  // GET MODE
  async function getPaymentMode() {
    try {
      const res = await axios.get("/api/walletMode/get-mode", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setPaymentMode(res.data.data.paymentMode);
        setDesc(res.data.data.desc);
        setImg(res.data.data.image);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getPaymentMode();
  }, []);

  const coinsArray = [
    {
      coins: 20,
      price: 20,
    },
    {
      coins: 50,
      price: 50,
    },
    {
      coins: 100,
      price: 100,
    },
    {
      coins: 500,
      price: 500,
    },
    {
      coins: 1000,
      price: 1000,
    },
    {
      coins: 2000,
      price: 2000,
    },
    {
      coins: 5000,
      price: 5000,
    },
    {
      coins: 10000,
      price: 10000,
    },
  ];

  useEffect(() => {
    getUserData(null, null, setBalance);
  }, []);

  const generateOrderId = () => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    const randomNum = Math.floor(1000 + Math.random() * 9000); // Ensures a 4-digit number
    const orderId = `${year}${month}${day}${hours}${minutes}${seconds}${randomNum}`;

    setOrderId(orderId);
  };

  useEffect(() => {
    generateOrderId();
    setPrice(coinsArray[0].price);
  }, []);

  function checkPlaceOrder() {
    if (paymentMode === "barcode") {
      handleBarcodePay();
    } else {
      handlePay();
    }
  }

  // barcode
  async function handleBarcodePay() {
    try {
      if (!utr || utr === "") {
        return message.error("Enter Transaction ID / UTR");
      }
      const paymentObject = {
        orderId: orderId,
        email: user?.email,
        mobile: user?.mobile,
        amount: price,
        utr: utr,
      };
      const res = await axios.post("/api/wallet/barcode", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        getUserWalletHistory();
        setTab(1);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  // onegateway
  async function handlePay() {
    try {
      setLoading(true);
      const paymentObject = {
        orderId: orderId,
        customerName: user?.fname,
        customerEmail: user?.email,
        customerNumber: user?.mobile,
        amount: price.toString(),
        paymentNote: "Coin Topup",
      };
      const response = await axios.post("/api/wallet/addmoney", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.paymentUrl) {
        window.location.href = response.data.data.paymentUrl;
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
      setLoading(false);
    }
  }

  // get wallet history
  async function getUserWalletHistory() {
    try {
      const res = await axios.post(
        "/api/wallet/wallet-history",
        {
          mobile: user?.mobile,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setPayments(res.data.data.reverse());
      }
    } catch (error) {
      console.log(error);
    }
  }
  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    if (user?.mobile) {
      setForm((prev) => ({
        ...prev,
        email: user?.email,
        mobile: user?.mobile,
      }));
      getUserWalletHistory();
    }
  }, []);

  return (
    <Layout>
      <DashboardLayout>
        <div className="wallet-dash">
          {tab === 1 && (
            <div className="wallet-dash-card w-100 wallet-dash-card-one">
              <span>Total Balance</span>
              <p>₹{parseFloat(balance).toFixed(2)}</p>
              <span onClick={() => setTab(3)}>
                Add Coin
                <AddCircleIcon className="icon ms-1" />
              </span>
            </div>
          )}

          {/* TXN HISTORY  */}
          {tab === 1 && (
            <div className="wallet-dash-card txn" onClick={() => setTab(0)}>
              <div>
                <FormatListBulletedIcon className="icon me-2" />
                Transaction History
              </div>
              <ArrowForwardIosIcon className="icon" />
            </div>
          )}

          {/* USER WALLET HISTORY DESKTOP*/}
          {tab === 0 && (
            <div className="desktoptable d-none d-md-none d-lg-block w-100">
              <div className="backbtn text-white" onClick={() => setTab(1)}>
                <ArrowBackIosIcon className="icon" />
                <span>Back</span>
              </div>
              {/* <h6 className="text-white">Wallet History</h6> */}
              <table className="table">
                <thead>
                  <tr>
                    <th>
                      <small>OrderId</small>
                    </th>
                    <th>
                      <small>Type</small>
                    </th>
                    <th>
                      <small>Product</small>
                    </th>
                    <th>
                      <small>Amount</small>
                    </th>
                    <th>
                      <small>Balance Before</small>
                    </th>
                    <th>
                      <small>Balance After</small>
                    </th>
                    <th>
                      <small>Date</small>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {payments?.length === 0 ? (
                    <tr>
                      <td className="text-center" colSpan={100}>
                        No Record Found
                      </td>
                    </tr>
                  ) : (
                    payments.map((item, index) => {
                      return (
                        <tr>
                          <td>
                            <small>{item?.orderId}</small>
                          </td>
                          <td>
                            <b>
                              <small>
                                {!item?.admin &&
                                  item?.type === "addmoney" &&
                                  "ADD MONEY"}{" "}
                                {!item?.admin &&
                                  item?.type === "order" &&
                                  "ORDER"}
                                {!item?.admin &&
                                  item?.type === "refund" &&
                                  "REFUND"}
                                {item?.admin &&
                                  item?.type === "addmoney" &&
                                  "ADD MONEY BY ADMIN"}
                                {item?.admin &&
                                  item?.type === "refund" &&
                                  "REFUND BY ADMIN"}
                                {item?.admin &&
                                  item?.type === "reward" &&
                                  "REWARD BY ADMIN"}
                              </small>
                            </b>
                          </td>
                          <td>
                            <small>{item?.product}</small>
                          </td>
                          <td>
                            <small>{item?.amount}</small>
                          </td>
                          <td>
                            <small>{item?.balanceBefore}</small>
                          </td>
                          <td>
                            <small>{item?.balanceAfter}</small>
                          </td>
                          <td>
                            <small>
                              {new Date(item?.createdAt).toLocaleString(
                                "default",
                                {
                                  day: "numeric",
                                  month: "numeric",
                                  year: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  second: "numeric",
                                }
                              )}
                            </small>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          )}

          {/* USER WALLET HISTORY MOBILE*/}
          {tab === 0 && (
            <div className="d-block d-md-block d-lg-none w-100">
              <div className="backbtn text-white" onClick={() => setTab(1)}>
                <ArrowBackIosIcon className="icon" />
                <span>Back</span>
              </div>
              <div className="mobile-wallet-history">
                {payments?.length === 0 ? (
                  <p className="m-0 text-center">No Record Found</p>
                ) : (
                  payments.map((item, index) => {
                    return (
                      <div className="history">
                        <div className="item">
                          <h4 className="m-0">{item?.product}</h4>
                          <span
                            className={`${
                              item?.type === "order"
                                ? "text-danger"
                                : item?.type === "pending"
                                ? "text-warning"
                                : "text-success"
                            }`}
                          >
                            {item?.type === "order" ? "-" : "+"}
                            {item?.amount}
                          </span>
                        </div>
                        <div className="item">
                          <span className="text-muted">
                            <small>{item?.orderId}</small>
                          </span>
                          <span className="text-muted">
                            <small>Avl Balance: ₹{item?.balanceAfter}</small>
                          </span>
                        </div>
                        <div className="item">
                          <span className="text-success">
                            <small>
                              {new Date(item?.createdAt).toLocaleString(
                                "default",
                                {
                                  day: "numeric",
                                  month: "numeric",
                                  year: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  second: "numeric",
                                }
                              )}
                            </small>
                          </span>
                          <span className="text-primary">
                            <b>
                              <small>{item?.type.toUpperCase()}</small>
                            </b>
                          </span>
                        </div>
                        {index !== payments.length - 1 && <hr />}
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          )}

          {/* ADD COIN */}
          {tab === 3 && (
            <>
              <div className="add-coin-container">
                <div className="backbtn text-white" onClick={() => setTab(1)}>
                  <ArrowBackIosIcon className="icon" />
                  <span>Back</span>
                </div>
                <div className="addmount">
                  <h4>Enter Amount to Add</h4>
                  <input
                    type="number"
                    name="amount"
                    placeholder="Enter amount"
                    value={form?.amount}
                    onChange={(e) => {
                      handleChange(e);
                      setPrice(e.target.value);
                    }}
                    className="form-control mb-3"
                  />
                </div>
                <h4>Select your amount</h4>
                <div className="coin-container">
                  {coinsArray?.map((item, index) => {
                    return (
                      <div
                        className={`coin ${price === item?.price && "active"}`}
                        onClick={() => {
                          setPrice(item?.price);
                          setForm({ ...form, amount: item?.price });
                        }}
                      >
                        <p>{parseFloat(item?.coins).toFixed(2)} Coins</p>
                        <p>₹{parseFloat(item?.price).toFixed(2)}</p>
                      </div>
                    );
                  })}
                </div>

                {paymentMode && paymentMode === "barcode" && (
                  <div className="walletbarcode">
                    <img src={`https://coinsup.in/${img}`} alt="" />
                    <div className="utrinput">
                      <label className="text-white">Transaction Id</label>
                      <input
                        placeholder="Enter transaction Id"
                        className="form-control"
                        onChange={(e) => setUtr(e.target.value)}
                        value={utr}
                        type="text"
                      />
                    </div>
                    <button className="buy-now w-100" onClick={checkPlaceOrder}>
                      Submit
                    </button>
                    <p>{desc}</p>
                  </div>
                )}

                {paymentMode !== "barcode" && (
                  <button
                    className="buy-now confirmbtn w-100"
                    onClick={checkPlaceOrder}
                  >
                    Confirm
                  </button>
                )}

                <p className="mt-2 text-white text-center">
                  <small>
                    By cliking "Confirm" agree to{" "}
                    <Link to="/terms">User's Terms and Conditions</Link> &{" "}
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </small>
                </p>
                <div className="d-flex payment-secured">
                  <VerifiedUserIcon className="icon" />
                  <span>
                    Payment <br /> Secured
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default Wallet;
