import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import "./Failure.css";
import { useSelector } from "react-redux";
import axios from "axios";

const Success = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState(null);
  const { user } = useSelector((state) => state.user);

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setForm(res.data.data.user);
        } else {
          localStorage.removeItem("token");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div className="failure-container success-container ">
      <TaskAltOutlinedIcon className="icon" />
      <h4>Order Successful</h4>
      <p className="m-0">Thank You for Your Order, {form?.fname}!</p>
      <p className="my-3">
        <Link to="/orders">View Order History</Link>
      </p>
      <div className="actionbtns">
        <button onClick={() => navigate("/")}>Continue Ordering</button>
      </div>
    </div>
  );
};

export default Success;
