import React from "react";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import FeedIcon from "@mui/icons-material/Feed";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import HistoryIcon from "@mui/icons-material/History";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import ChatIcon from "@mui/icons-material/Chat";
import "./MobileButtons.css";

const MobileButtons = () => {
  const navigate = useNavigate();
  return (
    <div className="d-flex d-md-flex d-lg-none mobile-buttons-container">
      <div className="buttons" onClick={() => navigate("/wallet")}>
        <div className="iconcontainer">
          <AddIcon className="icon" />
        </div>
        <span>Add Money</span>
      </div>
      <div className="buttons" onClick={() => navigate("/payments")}>
        <div className="iconcontainer">
          <AccountBalanceWalletIcon className="icon" />
        </div>
        <span>Payments</span>
      </div>
      <div className="buttons" onClick={() => navigate("/orders")}>
        <div className="iconcontainer">
          <HistoryIcon className="icon" />
        </div>
        <span>Order History</span>
      </div>
      {/* <div className="buttons" onClick={() => navigate("/refer-and-earn")}>
        <div className="iconcontainer">
          <GroupAddIcon className="icon" />
        </div>
        <span>Refer & Earn</span>
      </div> */}
      <div className="buttons" onClick={() => navigate("/leaderboard")}>
        <div className="iconcontainer">
          <EmojiEventsIcon className="icon" />
        </div>
        <span>Leaderboard</span>
      </div>
      {/* <div className="buttons" onClick={() => navigate("/news")}>
        <div className="iconcontainer">
          <FeedIcon className="icon" />
        </div>
        <span>News</span>
      </div> */}
      {/* <div className="buttons" onClick={() => navigate("/support")}>
        <div className="iconcontainer">
          <ChatIcon className="icon" />
        </div>
        <span>Support</span>
      </div> */}
      {/* <div className="buttons" onClick={() => navigate("/wallet")}>
        <div className="iconcontainer">
          <AccountBalanceWalletIcon className="icon" />
        </div>
        <span>Wallet</span>
      </div> */}
    </div>
  );
};

export default MobileButtons;
