import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import axios from "axios";
import Loader from "../pages/Loader";
import "./Orders.css";

const Orders = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [allOrders, setAllOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllUserOrders = async () => {
    try {
      setLoading(true);
      const res = await axios.post(
        "/api/order/get-user-orders",
        { email: user?.email },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setAllOrders(res.data.data.reverse());
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (user !== null) {
      getAllUserOrders();
    }
  }, [user]);

  function getOrderStatus(value) {
    switch (value) {
      case "pending":
        return "text-warning";
      case "cancelled":
        return "text-danger";
      case "processing":
        return "text-primary";
      case "success":
        return "text-success";
      case "refunded":
        return "text-primary";
      default:
        return "text-dark";
    }
  }

  return (
    <Layout>
      <DashboardLayout>
        <div className="user-order-container">
          {allOrders.length === 0 ? (
            <div className="no-order-found">
              <b>No Order Found</b>
              <button
                className="btn text-decoration-underline"
                onClick={() => navigate("/games")}
              >
                Go to Shop
              </button>
            </div>
          ) : (
            <>
              <table className="w-100 table d-none d-md-none d-lg-table">
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Total</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allOrders?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item?.orderId}</td>
                        <td>
                          {item?.createdAt
                            ? new Date(item.createdAt).toLocaleDateString(
                                "default",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  second: "numeric",
                                }
                              )
                            : ""}
                        </td>
                        <td>{item?.status}</td>
                        <td>{item?.price}</td>
                        <td>
                          <button
                            onClick={() =>
                              navigate(`/view-order/${item?.orderId}`)
                            }
                            className="view-btn"
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="orderhistory d-block d-md-block d-lg-none">
                <p>Order History</p>
                {allOrders &&
                  allOrders.map((item, index) => {
                    return (
                      <div className="history">
                        <div className="item">
                          <h4 className="m-0">{item?.p_info}</h4>
                          <b>
                            <span>₹{item?.price}</span>
                          </b>
                        </div>
                        <div className="item">
                          <span className="text-muted">
                            <small>{item?.orderId}</small>
                          </span>
                          <span className="text-dark">
                            <small>
                              {item?.userId} {item?.zoneId && item?.zoneId}
                            </small>
                          </span>
                        </div>
                        <div className="item">
                          <span className="text-muted">
                            <small>{item?.amount}</small>
                          </span>
                          <span className="text-primary">
                            <small>Paid using {item?.paymentMode}</small>
                          </span>
                        </div>
                        <div className="item">
                          <span className="text-success">
                            <small>
                              {new Date(item?.createdAt).toLocaleString(
                                "default",
                                {
                                  day: "numeric",
                                  month: "numeric",
                                  year: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  second: "numeric",
                                }
                              )}
                            </small>
                          </span>
                          <span className={`${getOrderStatus(item?.status)}`}>
                            {item?.status}
                          </span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </>
          )}
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default Orders;
