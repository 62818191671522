import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { message } from "antd";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "../user/components/DashboardLayout";
import axios from "axios";
import "./PaymentHistory.css";

const PaymentHistory = () => {
  const { user } = useSelector((state) => state.user);
  const [payments, setPayments] = useState([]);

  async function getUserPayments() {
    try {
      const res = await axios.post(
        "/api/payment/get-user-payments",
        { mobile: user?.mobile },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setPayments(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (user !== null) {
      getUserPayments();
    }
  }, [user]);

  return (
    <Layout>
      <DashboardLayout>
        <div className="payment-containers d-none d-md-none d-lg-block">
          <table className="table">
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Order ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Amount</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {payments?.length === 0 ? (
                <tr>
                  <td colSpan={100}>
                    <p className="text-center m-0">No Record Found</p>
                  </td>
                </tr>
              ) : (
                payments?.map((item, index) => {
                  return (
                    <tr>
                      <td>
                        <small>{index + 1}</small>
                      </td>
                      <td>
                        <small>{item?.txnId}</small>
                      </td>
                      <td>
                        <small>{item?.name}</small>
                      </td>
                      <td>
                        <small>{item?.email}</small>
                      </td>
                      <td>
                        <small>{item?.amount}</small>
                      </td>
                      <td>
                        <small>
                          {new Date(item?.createdAt).toLocaleString(
                            "default",
                            {}
                          )}
                        </small>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        <div className="payment-containers d-block d-md-block d-lg-none">
          <p>Payment History</p>
          <hr />
          {payments?.length === 0 ? (
            <p className="text-center m-0">No Record Found</p>
          ) : (
            payments?.map((item, index) => {
              return (
                <div className="payments w-100">
                  <div className="item">
                    <h5>{item?.txnId}</h5>
                    <span>₹{item?.amount}</span>
                  </div>
                  <div className="item">
                    <span className="text-muted">
                      <small>
                        {new Date(item?.createdAt).toLocaleString("default", {
                          day: "numeric",
                          month: "numeric",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                        })}
                      </small>
                    </span>
                    <span className="fw-bold text-primary">
                      <small>{item?.type.toUpperCase()}</small>
                    </span>
                  </div>
                  {index !== payments.length - 1 && <hr />}
                </div>
              );
            })
          )}
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default PaymentHistory;
